import { Injectable } from '@angular/core';
import { IdType, NormalizedSingle, PayloadAction, SingleStateToComponentAdapter } from '@industi/ngx-common';
import { Client } from '../models/client';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector } from '@ngrx/store';
import {
  createClientDetails,
  createClientDetailsErrors,
  createClientDetailsLoading,
  createClientDetailsSuccess
} from '../selectors/get-details.selectors';
import {
  ClientLoadDetailsAction,
  ClientLoadDetailsClearAction,
  ClientLoadDetailsClearErrorsAction,
  ClientLoadDetailsFailAction,
  ClientLoadDetailsSuccessAction
} from '../actions/load-details.actions';

@Injectable({
  providedIn: 'root'
})
export class ClientDetailsAdapterService
  extends SingleStateToComponentAdapter<Client> {

  public apiOutputModelToAppItemModel(apiOutput: Client): Client {
    return apiOutput;
  }

  public getDataSelector(id: IdType): Selector<any, Client> {
    return createClientDetails(id);
  }

  public getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createClientDetailsErrors(id);
  }

  public getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createClientDetailsLoading(id);
  }

  public getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createClientDetailsSuccess(id);
  }

  public requestAction(id: IdType): Action {
    return new ClientLoadDetailsAction({ id });
  }

  public requestCancelAction(id: IdType): Action {
    return undefined;
  }

  public requestClearAction(id: IdType): Action {
    return new ClientLoadDetailsClearAction({ id });
  }

  public requestClearErrorsAction(id: IdType): Action {
    return new ClientLoadDetailsClearErrorsAction({ id });
  }

  public requestFailAction(id: IdType, errors: HttpErrorResponse): PayloadAction {
    return new ClientLoadDetailsFailAction({ id, errors });
  }

  public requestSuccessAction(id: IdType, res: NormalizedSingle): PayloadAction {
    return new ClientLoadDetailsSuccessAction({ id, res });
  }
}
