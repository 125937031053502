import { createSelector } from '@ngrx/store';
import {
  ByKey,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType
} from '@industi/ngx-common';
import { denormalize } from 'normalizr';
import { CarbonFormOperationByIdState, getCarbonFormOperationsById } from '../form.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { CarbonForm } from '../models/form';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';

const createState = (id: IdType) => createSelector(
  getCarbonFormOperationsById,
  (state: ByKey<CarbonFormOperationByIdState>) => state && state[id] && state[id].details
);

export const createCarbonFormDetailsLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createCarbonFormDetailsErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createCarbonFormDetailsSuccess = (id: IdType) => createStateSuccessSelector(createState(id));

export const createCarbonFormDetails = (id: IdType) => createSelector(
  createEntitiesSelector([
    EntityTypes.carbonForm
  ]),
  (entities: EntityState) => denormalize(id, Entities[EntityTypes.carbonForm], entities) as CarbonForm
);
