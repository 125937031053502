import { Injectable } from '@angular/core';
import { EditStateToComponentAdapter, IdType, NormalizedSingle, PayloadAction } from '@industi/ngx-common';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector } from '@ngrx/store';
import * as _ from 'lodash';
import { Item } from '../models/item';
import {
  ItemAddAction,
  ItemAddClearAction,
  ItemAddClearErrorsAction,
  ItemAddFailAction,
  ItemAddSuccessAction
} from '../actions/add.actions';
import { createItemAddErrors, createItemAddLoading, createItemAddSuccess } from '../selectors/get-add.selectors';
import { ItemPayload } from '../models/item-payload';
import { FilesPayload } from '../../../common/models/files-payload';
import { ItemDraft } from '../models/item-draft';

@Injectable({
  providedIn: 'root'
})
export class ItemAddAdapterService
  extends EditStateToComponentAdapter<Item, FilesPayload<ItemPayload>, ItemDraft> {

  public apiOutputModelToAppItemModel(apiOutput: Item): Item {
    return apiOutput;
  }

  public appModelToApiInputModel(appModel: ItemDraft): FilesPayload<ItemPayload> {
    return {
      files: appModel?.files || [],
      json: {
        ..._.omit(appModel, ['files', 'price.discountGroupId']),
        discountGroupId: appModel?.price?.discountGroupId || null
      }
    };
  }

  public getDataSelector(id: IdType): Selector<any, Item> {
    return () => null;
  }

  public getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createItemAddErrors(id);
  }

  public getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createItemAddLoading(id);
  }

  public getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createItemAddSuccess(id);
  }

  public requestAction(id: IdType, payload?: FilesPayload<ItemPayload>): Action {
    return new ItemAddAction({ id, data: payload });
  }

  public requestFailAction(id: IdType, errors: HttpErrorResponse): PayloadAction {
    return new ItemAddFailAction({ id, errors });
  }

  public requestSuccessAction(id: IdType, res: NormalizedSingle): PayloadAction {
    return new ItemAddSuccessAction({ id, res });
  }

  public requestCancelAction(id: IdType): Action {
    return undefined;
  }

  public requestClearAction(id: IdType): Action {
    return new ItemAddClearAction({ id });
  }

  public requestClearErrorsAction(id: IdType): Action {
    return new ItemAddClearErrorsAction({ id });
  }
}
