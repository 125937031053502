import { createSelector } from '@ngrx/store';
import {
  ByKey,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType
} from '@industi/ngx-common';
import { getItemOperationsById, ItemOperationByIdState } from '../common.reducer';

const createState = (id: IdType) => createSelector(
  getItemOperationsById,
  (state: ByKey<ItemOperationByIdState>) => state && state[id] && state[id].add
);

export const createItemAddLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createItemAddErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createItemAddSuccess = (id: IdType) => createStateSuccessSelector(createState(id));
