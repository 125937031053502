import { Injectable } from '@angular/core';
import { EditStateToComponentAdapter, IdType, NormalizedSingle, PayloadAction } from '@industi/ngx-common';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector } from '@ngrx/store';
import {
  ServiceProjectAddAction,
  ServiceProjectAddClearAction,
  ServiceProjectAddClearErrorsAction,
  ServiceProjectAddFailAction,
  ServiceProjectAddSuccessAction
} from '../actions/add.actions';
import { createServiceProjectAddErrors, createServiceProjectAddLoading, createServiceProjectAddSuccess } from '../selectors/get-add.selectors';
import * as _ from 'lodash';
import { ServiceProject } from '../models/project';
import { ServiceProjectDraft } from '../models/project-draft';
import { ServiceProjectPayload } from '../models/project-payload';
import { SymfonyDateUtil } from '../../../../symfony/utils/symfony-date-util';

@Injectable({
  providedIn: 'root'
})
export class ServiceProjectAddAdapterService
  extends EditStateToComponentAdapter<ServiceProject, ServiceProjectPayload, ServiceProjectDraft> {

  public apiOutputModelToAppItemModel(apiOutput: ServiceProject): ServiceProjectDraft {
    return {
      basicData: {
        ..._.omit(apiOutput, ['estimationLaborCost', 'partCost'])
      },
      price: {
        ..._.pick(apiOutput, ['estimationLaborCost', 'partCost'])
      }
    };
  }

  public appModelToApiInputModel(appModel: ServiceProjectDraft): ServiceProjectPayload {
    return {
      ..._.omitBy(_.omit(appModel?.basicData, ['basicData.brand', 'basicData.modelVersionId']), _.isNil),
      ..._.omitBy(appModel?.price, _.isNil),
      modelId: appModel?.basicData?.modelVersionId ? appModel?.basicData?.modelVersionId : appModel?.basicData?.modelId,
      ...( appModel?.basicData?.startedAt ? { startedAt: SymfonyDateUtil.dateToSymfonyDate(appModel?.basicData?.startedAt) } : {}),
      ...( appModel?.basicData?.endedAt ? { endedAt: SymfonyDateUtil.dateToSymfonyDate(appModel?.basicData?.endedAt) } : {})
    };
  }

  public getDataSelector(id: IdType): Selector<any, ServiceProject> {
    return () => null;
  }

  public getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createServiceProjectAddErrors(id);
  }

  public getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createServiceProjectAddLoading(id);
  }

  public getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createServiceProjectAddSuccess(id);
  }

  public requestAction(id: IdType, payload?: ServiceProjectPayload): Action {
    return new ServiceProjectAddAction({ id, data: payload });
  }

  public requestFailAction(id: IdType, errors: HttpErrorResponse): PayloadAction {
    return new ServiceProjectAddFailAction({ id, errors });
  }

  public requestSuccessAction(id: IdType, res: NormalizedSingle): PayloadAction {
    return new ServiceProjectAddSuccessAction({ id, res });
  }

  public requestCancelAction(id: IdType): Action {
    return undefined;
  }

  public requestClearAction(id: IdType): Action {
    return new ServiceProjectAddClearAction({ id });
  }

  public requestClearErrorsAction(id: IdType): Action {
    return new ServiceProjectAddClearErrorsAction({ id });
  }
}
