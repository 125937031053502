import { Injectable } from '@angular/core';
import { IdType, ListStateToComponentAdapter } from '@industi/ngx-common';
import { Action, Selector } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import {
  DictionaryCourierLoadListAction,
  DictionaryCourierLoadListChangeParamsAction,
  DictionaryCourierLoadListClearAction,
  DictionaryCourierLoadListClearParamsAction
} from '../actions/list.actions';
import {
  createDictionaryCourierListData,
  createDictionaryCourierListErrors,
  createDictionaryCourierListLoading,
  createDictionaryCourierListParams,
  createDictionaryCourierListSuccess,
  createDictionaryCourierListTotalCount
} from '../selectors/get-load-list.selectors';
import { AppGlobal } from '../../../../shared/app-global';
import { Dictionary } from '../../../../shared/models/dictionary';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

@Injectable({
  providedIn: 'root'
})
export class DictionaryCourierListAdapterService
  extends ListStateToComponentAdapter<Dictionary, SymfonyPaginationParams> {

  apiItemToTableItem(apiModel: Dictionary): Dictionary {
    return apiModel;
  }

  changeParamsAction(id: IdType, params: SymfonyPaginationParams): Action {
    return new DictionaryCourierLoadListChangeParamsAction({ id, params });
  }

  clearAction(id: IdType): Action {
    return new DictionaryCourierLoadListClearAction({ id });
  }

  clearParamsAction(id: IdType): Action {
    return new DictionaryCourierLoadListClearParamsAction({ id });
  }

  getDataSelector(id: IdType): Selector<any, Dictionary[]> {
    return createDictionaryCourierListData(id);
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createDictionaryCourierListErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createDictionaryCourierListLoading(id);
  }

  getParamsSelector(id: IdType): Selector<any, SymfonyPaginationParams> {
    return createDictionaryCourierListParams(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createDictionaryCourierListSuccess(id);
  }

  getTotalCountSelector(id: IdType): Selector<any, number> {
    return createDictionaryCourierListTotalCount(id);
  }

  loadListAction(id: IdType): Action {
    return new DictionaryCourierLoadListAction({ id });
  }

  tableParamsToApiParams(tableParams: SymfonyPaginationParams): SymfonyPaginationParams {
    return _.omitBy({
      ...tableParams,
      page: tableParams?.page || AppGlobal.listParams.page,
    } as SymfonyPaginationParams, _.isNil);
  }

}
