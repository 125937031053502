import { createSelector } from '@ngrx/store';
import {
  ByKey,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType
} from '@industi/ngx-common';
import { getCarbonFormOperationsById, CarbonFormOperationByIdState } from '../form.reducer';

const createState = (id: IdType) => createSelector(
  getCarbonFormOperationsById,
  (state: ByKey<CarbonFormOperationByIdState>) => state && state[id] && state[id].delete
);

export const createCarbonFormDeleteLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createCarbonFormDeleteErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createCarbonFormDeleteSuccess = (id: IdType) => createStateSuccessSelector(createState(id));
