import { Injectable } from '@angular/core';
import { IdType, PayloadAction, SingleStateToComponentAdapter } from '@industi/ngx-common';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector } from '@ngrx/store';
import {
  createCarbonFormDeleteErrors,
  createCarbonFormDeleteLoading,
  createCarbonFormDeleteSuccess
} from '../selectors/get-delete.selectors';
import {
  CarbonFormDeleteAction,
  CarbonFormDeleteClearAction,
  CarbonFormDeleteClearErrorsAction,
  CarbonFormDeleteFailAction,
  CarbonFormDeleteSuccessAction
} from '../actions/delete.actions';
import { CarbonForm } from '../models/form';

@Injectable({
  providedIn: 'root'
})
export class CarbonFormDeleteAdapterService
  extends SingleStateToComponentAdapter<CarbonForm> {

  apiOutputModelToAppItemModel(apiOutput: CarbonForm): CarbonForm {
    return apiOutput;
  }

  getDataSelector(id: IdType): Selector<any, CarbonForm> {
    return () => null;
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createCarbonFormDeleteErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createCarbonFormDeleteLoading(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createCarbonFormDeleteSuccess(id);
  }

  requestAction(id: IdType): Action {
    return new CarbonFormDeleteAction({ id });
  }

  requestCancelAction(id: IdType): Action {
    return undefined;
  }

  requestClearAction(id: IdType): Action {
    return new CarbonFormDeleteClearAction({ id });
  }

  requestClearErrorsAction(id: IdType): Action {
    return new CarbonFormDeleteClearErrorsAction({ id });
  }

  requestFailAction(id: IdType, errors: HttpErrorResponse): PayloadAction {
    return new CarbonFormDeleteFailAction({ id, errors });
  }

  requestSuccessAction(id: IdType): PayloadAction {
    return new CarbonFormDeleteSuccessAction({ id });
  }
}
