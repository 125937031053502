import { Normalized, PayloadAction, uniqueActionType } from '@industi/ngx-common';

export const ActionTypes = {
  updateEntities: uniqueActionType('[entities] Update entities')
};

export class UpdateEntitiesAction implements PayloadAction {
  readonly type = ActionTypes.updateEntities;

  constructor(public payload: Normalized) {
  }
}
