import { IdType, NormalizedSingle, PayloadAction, SingleStateToComponentAdapter } from '@industi/ngx-common';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector } from '@ngrx/store';
import {
  createDiagramDetails,
  createDiagramDetailsErrors,
  createDiagramDetailsLoading,
  createDiagramDetailsSuccess
} from '../selectors/get-details.selectors';
import {
  DiagramLoadDetailsAction,
  DiagramLoadDetailsClearAction,
  DiagramLoadDetailsClearErrorsAction,
  DiagramLoadDetailsFailAction,
  DiagramLoadDetailsSuccessAction
} from '../actions/load-details.actions';
import { Diagram } from '../models/diagram';

@Injectable({
  providedIn: 'root'
})
export class DiagramDetailsAdapterService
  extends SingleStateToComponentAdapter<Diagram> {

  public apiOutputModelToAppItemModel(apiOutput: Diagram): Diagram {
    return apiOutput;
  }

  public getDataSelector(id: IdType): Selector<any, Diagram> {
    return createDiagramDetails(id);
  }

  public getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createDiagramDetailsErrors(id);
  }

  public getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createDiagramDetailsLoading(id);
  }

  public getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createDiagramDetailsSuccess(id);
  }

  public requestAction(id: IdType): Action {
    return new DiagramLoadDetailsAction({id});
  }

  public requestCancelAction(id: IdType): Action {
    return undefined;
  }

  public requestClearAction(id: IdType): Action {
    return new DiagramLoadDetailsClearAction({id});
  }

  public requestClearErrorsAction(id: IdType): Action {
    return new DiagramLoadDetailsClearErrorsAction({id});
  }

  public requestFailAction(id: IdType, errors: HttpErrorResponse): PayloadAction {
    return new DiagramLoadDetailsFailAction({id, errors});
  }

  public requestSuccessAction(id: IdType, res: NormalizedSingle): PayloadAction {
    return new DiagramLoadDetailsSuccessAction({id, res});
  }

}
